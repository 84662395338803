<template>
  <div class="container">
    <div class="d-flex justify-center align-center">
      <div class="text-subtitle-2 ma-2 text-center">
        {{ $vuetify.lang.t("$vuetify.dashboard.activityTitle") }}
      </div>
      <v-btn icon @click="toggleDateMode">
        <v-icon v-if="isRange">mdi-calendar</v-icon>
        <v-icon v-else>mdi-calendar-range</v-icon>
      </v-btn>
    </div>

    <div class="d-flex justify-center mt-4">
      <div class="d-flex">
        <v-btn icon v-if="!isRange" @click="addDay(-1)">
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
        <date-picker
          :key="'start-date-picker' + snapshotStartDate"
          outlined
          dense
          style="max-width: 300px"
          :label="$vuetify.lang.t('$vuetify.base.startDate')"
          :defaultNowOnNull="false"
          :show-hint="false"
          :show-icon="false"
          v-model="snapshotStartDate"
          class="mx-2"
        />
        <date-picker
          v-if="isRange"
          :key="'end-date-picker' + snapshotEndDate"
          outlined
          dense
          style="max-width: 300px"
          :label="$vuetify.lang.t('$vuetify.base.endDate')"
          :defaultNowOnNull="false"
          :show-hint="false"
          :show-icon="false"
          v-model="snapshotEndDate"
          class="mx-2"
        />
        <v-btn icon v-if="!isRange" @click="addDay(1)">
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
    </div>

    <production-snapshot
      :startDate="snapshotStartDate"
      :endDate="snapshotEndDate"
    />
    <div class="d-flex justify-space-around flex-column flex-lg-row mt-8">
      <receiving-snapshot
        :startDate="snapshotStartDate"
        :endDate="snapshotEndDate"
      />
      <packaging-snapshot
        :startDate="snapshotStartDate"
        :endDate="snapshotEndDate"
      />
    </div>

    <v-divider class="my-4" />

    <div class="mb-8">
      <inventory-balances @inventoryLoaded="inventoryReady = true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import moment from "moment";
const InventoryBalances = () =>
  import("@/components/dashboard/InventoryBalances.vue");
const ProductionSnapshot = () =>
  import("@/components/dashboard/ProductionSnapshot.vue");
const ReceivingSnapshot = () =>
  import("@/components/dashboard/ReceivingSnapshot.vue");
const PackagingSnapshot = () =>
  import("@/components/dashboard/PackagingSnapshot.vue");
const Secured = () => import("@/components/common/Secured.vue");

export default {
  data: () => {
    return {
      snapshotStartDate: new moment().format("YYYY-MM-DD"),
      snapshotEndDate: new moment().format("YYYY-MM-DD"),
      inventoryReady: false,
      isRange: false,
    };
  },
  watch:{
    snapshotStartDate(newVal){
      this.snapshotStartDate = newVal;
      if(!this.isRange){
        this.snapshotEndDate = this.snapshotStartDate;
      }
    }
  },
  components: {
    InventoryBalances,
    ProductionSnapshot,
    ReceivingSnapshot,
    PackagingSnapshot,
    Secured,
  },
  computed: {
    ...mapGetters("inventoryBalances", ["startBalanceService"]),
  },
  methods: {
    toggleDateMode() {
      this.isRange = !this.isRange;
      if (this.isRange) {
        // Set the start date to the start of the current week
        this.snapshotStartDate = moment().startOf("week").format("YYYY-MM-DD");
        // Set the end date to the end of the week or the current date, whichever is earlier
        let endOfWeek = moment().endOf("week");
        this.snapshotEndDate = endOfWeek.isAfter(moment())
          ? moment().format("YYYY-MM-DD")
          : endOfWeek.format("YYYY-MM-DD");
      } else {
        // Set the start and end dates to the current date when switching back to single date mode
        // this.snapshotStartDate = moment().format("YYYY-MM-DD");
        this.snapshotEndDate = this.snapshotStartDate;
      }
    },

    addDay(day) {
      this.snapshotStartDate = moment(this.snapshotStartDate, "YYYY-MM-DD")
        .add(day, "days")
        .format("YYYY-MM-DD");
      this.snapshotEndDate = this.snapshotStartDate;
    },
  },
};
</script>

<style></style>
